<template>
  <div style="padding: 10px; border: 1px solid #ccc; background: #fff; border-radius: 10px;">
    <div v-if="duplicateNameSurveys.length > 0">
      <div style="color: #555;">
        Name "{{nameValue}}" in {{duplicateNameSurveys.length}} other
        
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" style="text-decoration: underline;">survey<span v-if="duplicateNameSurveys.length > 1">s</span></span>
          </template>
          <div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Survey Label</th>
                    <th>Survey Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(duplicateNameSurvey, index) in duplicateNameSurveys" :key="index">
                    <td>{{duplicateNameSurvey.label}}</td>
                    <td>{{duplicateNameSurvey.name}}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-tooltip>
      </div>
    </div>
    <div>
      <v-switch
        v-model="woenennOverwrite"
        label="WOENENN Overwrite"
      ></v-switch>

      <div v-if="woenennOverwrite">
        <v-text-field v-model="newWoenennQuestionVariable.title" label="Title"></v-text-field>
        <v-text-field v-model="newWoenennQuestionVariable.description" label="Description"></v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
import * as SurveyKnockoutUi from "survey-knockout-ui"

import surveyJsUtil from '@/services/surveyjs/util'

export default {
  name: 'surveyjs-creator-is-woenenn-question-variable',
  data: () => ({
    bulkWoenennQuestionVariables: null,
    duplicateNameSurveys: [],
    nameValue: null,
    newWoenennQuestionVariable: null,
    questions: null,
    surveyCreatorQuestion: null,
    surveys: null,
    woenennDescription: null,
    woenennOverwrite: false,
    woenennTitle: null,
    woenennQuestionVariable: null,
    woenennQuestionVariables: null
  }),
  props: {
    question: Object
  },
  watch: {
    woenennOverwrite(woenennOverwrite, oldWoenennOverwrite) {
     if (woenennOverwrite) {
        if (!this.newWoenennQuestionVariable) {
          if (this.woenennQuestionVariable) {
            this.newWoenennQuestionVariable = {
              title: this.woenennQuestionVariable.title,
              description: this.woenennQuestionVariable.description
            }

            this.bulkWoenennQuestionVariables.replace[this.nameValue] = this.newWoenennQuestionVariable

            delete this.bulkWoenennQuestionVariables.delete[this.nameValue]
          } else {
            this.newWoenennQuestionVariable = {
              title: this.question.survey.getQuestionByName("title").value,
              description: this.question.survey.getQuestionByName("description").value
            }

            this.bulkWoenennQuestionVariables.insert[this.nameValue] = this.newWoenennQuestionVariable
          }
        }
      } else {
        if (this.woenennQuestionVariable) {
          delete this.bulkWoenennQuestionVariables.replace[this.nameValue]
          this.bulkWoenennQuestionVariables.delete[this.nameValue] = true
        } else {
          delete this.bulkWoenennQuestionVariables.insert[this.nameValue]
        }

        this.newWoenennQuestionVariable = null
      }
    },

    nameValue(nameValue, oldNameValue) {
      let surveyCreatorSurvey = this.surveyCreatorQuestion.survey
      let surveyCreatorName = surveyCreatorSurvey.getQuestionByName('name').value

      this.duplicateNameSurveys = (this.questionMap[nameValue] || []).filter(survey => {
        return survey.name !== surveyCreatorName
      })

      if (oldNameValue !== null) {
        if (this.woenennOverwrite) {
          delete this.bulkWoenennQuestionVariables.insert[oldNameValue]
          delete this.bulkWoenennQuestionVariables.replace[oldNameValue]
        } else {
          delete this.bulkWoenennQuestionVariables.delete[oldNameValue]
        }

        this.woenennQuestionVariable = this.woenennQuestionVariables.find(woenennQuestionVariable => woenennQuestionVariable.name == nameValue)
        
        if (this.woenennQuestionVariable) {
          this.newWoenennQuestionVariable = {
            title: this.woenennQuestionVariable.title,
            description: this.woenennQuestionVariable.description
          }

          this.bulkWoenennQuestionVariables.replace[nameValue] = this.newWoenennQuestionVariable

          this.woenennOverwrite = true
        } else {
          this.newWoenennQuestionVariable = null
          this.woenennOverwrite = false
        }
      }
    }
  },
  methods: {
  },
  async beforeMount() {
    // TODO: It's not understood why the attriubute options of the questions is the surveyCreator object
    let surveyCreator = this.question.options
    let surveyCreatorWidgetInterface = surveyCreator._widgetInterface

    this.surveys = surveyCreatorWidgetInterface.surveys
    this.woenennQuestionVariables = surveyCreatorWidgetInterface.woenennQuestionVariables
    this.bulkWoenennQuestionVariables = surveyCreatorWidgetInterface.bulkWoenennQuestionVariables
    this.surveyCreatorQuestion = surveyCreatorWidgetInterface.question

    let questionMap = {}

    // need to unregister functions so function like "lookup"
    // are not executed
    surveyJsUtil.unregisterFunctions()

    for (let i = 0; i < this.surveys.length; ++i) {
      let survey = this.surveys[i]
      let surveyModel = new SurveyKnockoutUi.Model(survey.surveyDefinition)
      
      let questions = surveyModel.getAllQuestions()

      for (let j = 0; j < questions.length; ++j) {
        let question = questions[j]
        let questionSurveys = questionMap[question.name]

        if (!questionSurveys) {
          questionMap[question.name] = questionSurveys = []
        }
        
        if (!questionSurveys.find(questionSurvey => questionSurvey.name === survey.name)) {
          questionSurveys.push(survey)
        }
      }
    }

    surveyJsUtil.registerFunctions()

    this.questionMap = questionMap
    
    let nameQuestion = this.question.survey.getQuestionByName("name")
    let nameValue = nameQuestion.value

    this.nameValue = nameValue

    this.woenennQuestionVariable = this.woenennQuestionVariables.find(woenennQuestionVariable => woenennQuestionVariable.name == nameValue)

    if (this.woenennQuestionVariable) {
      if (this.bulkWoenennQuestionVariables.delete[nameValue]) {
        this.woenennOverwrite = false
      } else if (this.bulkWoenennQuestionVariables.replace[nameValue]) {
        this.newWoenennQuestionVariable = this.bulkWoenennQuestionVariables.replace[nameValue]

        this.woenennOverwrite = true
      } else {
        this.newWoenennQuestionVariable = {
          title: this.woenennQuestionVariable.title,
          description: this.woenennQuestionVariable.description
        }

        this.bulkWoenennQuestionVariables.replace[nameValue] = this.newWoenennQuestionVariable

        this.woenennOverwrite = true
      }
    } else {
      if (this.bulkWoenennQuestionVariables.insert[nameValue]) {
        this.newWoenennQuestionVariable = this.bulkWoenennQuestionVariables.insert[nameValue]

        this.woenennOverwrite = true
      } else {
        this.woenennOverwrite = false
      }
    }

    nameQuestion.onPropertyChanged.add((x1, x2) => {
      if (x2.name == 'value' && x2.newValue != x2.oldValue && x2.newValue !== undefined) {
        this.nameValue = x2.newValue
      }
    })
  }
}
</script>

<style lang="scss" scoped>
</style>