<template>
  <div>
    <v-dialog
      v-model="showSelectQuestionDialog"
      persistent
      scrollable
      width="80%"
    >
      <v-card v-if="showSelectQuestionDialog" style="height: 80vh; display: flex; flex-direction: column;">
        <v-card-title class="text-h6 white--text primary">
          Question Search
        </v-card-title>

        <v-card-text class="pa-0" style="display: flex; flex: 1 1 0;">
          <question-select :surveys="surveys" v-if="showSelectQuestionDialog" @selectQuestion="selectQuestion"></question-select>
        </v-card-text>

        <v-card-actions style="border-top: 1px solid rgb(233, 233, 233);">
          <v-btn text @click="showSelectQuestionDialog=false">{{$t('actions.cancel')}}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showDialog"
  
      persistent
      no-click-animation
      scrollable
      fullscreen
    >
      <v-card v-if="showDialog" flat>
        <v-card-title class="text-h6 white--text primary">
          Survey Editor
        </v-card-title>
        <v-card-text style="padding: 0; display: flex; flex: 1 0 0; flex-direction: column;">
          <div ref="survey-creator" style="flex: 1 0 0; display: flex; height: 0;"></div>
          <v-card-actions style="flex: 0 0 auto; background: rgb(243, 243, 243); border-top: 1px solid rgb(214, 214, 214);">
            <v-btn text small @click="openSelectQuestionDialog">Select Question</v-btn>
          </v-card-actions>
        </v-card-text>

        <v-card-actions dense style="border-top: 1px solid rgb(233, 233, 233);"> 
          <v-spacer></v-spacer>
          <v-btn text small @click="cancel">{{$t('actions.cancel')}}</v-btn>
          <v-btn small color="primary" @click="save">{{$t('actions.ok')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { SurveyCreator } from "survey-creator-knockout"
import "survey-core/survey.i18n"

import api from '@/services/api'

export default {
  name: 'surveyjs-creator-form',
  data: () => ({
    bulkWoenennQuestionVariables: null,
    creator: null,
    promiseResolve: null,
    question: null,
    showDialog: false,
    showSelectQuestionDialog: false,
    surveyDefinition: null
  }),
  props: {
    dialogObj: Object
  },
  async mounted() {
    this.$store.dispatch('setLoading', true)

    this.showDialog = true
    this.promiseResolve = this.dialogObj.resolve || function() {}
    
    let options = this.dialogObj.options

    this.surveyDefinition = options.surveyDefinition || {}
    this.question = options.question
    this.bulkWoenennQuestionVariables = options.bulkWoenennQuestionVariables

    await this.$nextTick()

    await this.initDialog()

    this.$store.dispatch('setLoading', false)
  },
  destroyed() {
    this.creator.dispose()
  },
  methods: {
    cancel() {
      this.close()

      this.promiseResolve({
        cancel: true
      })
    },

    close() {
      this.showDialog = false
    },

    async initDialog() {
      let surveys = (await api.call('getSurveys')).data
      this.surveys = surveys

      let creator = this.creator = new SurveyCreator({
        haveCommercialLicense: true,
        isAutoSave: true,
        pageEditMode: "bypage",
        /*questionTypes: [
          "boolean",
          "checkbox",
          "comment",
          "dropdown",
          "html-rendered",
          "matrix",
          "matrixdynamic",
          "paneldynamic",
          "radiogroup",
          "ranking",
          "rating",
          "text"
        ],*/
        showJSONEditorTab: true,
        showLogicTab: true,
        showTranslationTab: true,
        //showThemeTab: true
      })

      creator.onDefineElementMenuItems.add(function (editor, options) {
        if (!options.obj.isPage) {
          // Do not show required as menu items for questions in survey creator
          ['isrequired'].forEach(removeId => {
            let index = options.items.findIndex(item => item.id == removeId)

            index >= 0 && options.items.splice(index, 1)
          })
        }
      })

      creator.JSON = this.surveyDefinition      
      
      creator.saveSurveyFunc = (saveNo, callback) => {
        this.surveyDefinition = creator.JSON

        callback(saveNo, true)
      }

      let woenennQuestionVariables = (await api.call('getQuestionVariables')).data

      creator._widgetInterface = {
        question: this.question,
        surveys: surveys,
        woenennQuestionVariables: woenennQuestionVariables,
        bulkWoenennQuestionVariables: this.bulkWoenennQuestionVariables
      }

      creator.render(this.$refs['survey-creator'])
    },

    openSelectQuestionDialog() {
      this.showSelectQuestionDialog = true
    },

    async save() {
      this.close()

      this.promiseResolve({
        cancel: false,
        surveyDefinition: this.surveyDefinition,
        bulkWoenennQuestionVariables: this.bulkWoenennQuestionVariables
      })
    },

    selectQuestion(question) {
      let editingSurvey = this.creator.survey

      let page = editingSurvey.activePage
      
      if (!page) {
        if (editingSurvey.pageCount == 0) {
          // if there is no active page take the first page
          page = editingSurvey.addNewPage()
        } else {
          // create new page if there is no page
          page = editingSurvey.pages[0]
        }
      }
      
      page.addElement(question.clone())

      this.showSelectQuestionDialog = false
    }
  }
}
</script>

<style lang="scss">
    // TEMP: Layout fixes
    .spg-question__content {
      position: relative;
    }
  
    .sv_q_dropdown_clean-button {
      display: none;
    }
</style>
