import axios from 'axios'
import store from '@/store/index'
import { getInstance } from '@/auth'
import error from '@/services/error'
import { t } from '@/i18n'

const callInfo = (name, data) => {
	const infos = {
		getSources: data => ({
			method: 'get',
			url: '/api/sources'
		}),
		getViews: data => ({
			method: 'get',
			url: '/api/views'
		}),
		getSourceViews: data => ({
			method: 'get',
			url: '/api/sources/' + data.sourceName + '/views'
		}),
		getSourceView: data => ({
			method: 'get',
			url: '/api/sources/' + data.sourceName + '/views/' + data.viewName
		}),
		getSourceViewFilter: data => ({
			method: 'get',
			url: '/api/sources/' + data.sourceName + '/views/' + data.viewName + '/filters/' + data.filterVarName
		}),
		getSourceViewCatiResult: data => ({
			method: 'post',
			url: '/api/sources/' + data.sourceName + '/views/' + data.viewName + '/cati_result'
		}),
		getSourceViewSurveyResult: data => ({
			method: 'get',
			url: '/api/sources/' + data.sourceName + '/views/' + data.viewName + '/surveys/' + data.surveyId + '/result/' + data.resultId
		}),
		unlockSourceViewSurveyResult: data => ({
			method: 'get',
			url: '/api/sources/' + data.sourceName + '/views/' + data.viewName + '/surveys/' + data.surveyId + '/result/unlock/' + data.resultId
		}),
		getSourceViewResults: data => ({
			method: 'get',
			url: '/api/sources/' + data.sourceName + '/views/' + data.viewName + '/results'
		}),
		getSourceViewSortedResultIds: data => ({
			method: 'post',
			url: '/api/sources/' + data.sourceName + '/views/' + data.viewName + '/sorted_result_ids'
		}),
		getSourceViewPivotResults: data => ({
			method: 'post',
			url: '/api/sources/' + data.sourceName + '/views/' + data.viewName + '/pivot_results'
		}),
		getAnalysisSettings: data => ({
			method: 'get',
			url: '/api/sources/' + data.sourceName + '/analysis_settings/' + data.analysisSettingId
		}),
		deleteAnalysisSettings: data => ({
			method: 'delete',
			url: '/api/sources/' + store.state.source.name + '/analysis_settings/' + data.analysisSettingId
		}),
		moveAnalysisSettings: data => ({
			method: 'post',
			url: '/api/sources/' + store.state.source.name + '/move_analysis_settings/' + data.analysisSettingId
		}),
		saveAnalysisSettings: data => ({
			method: 'post',
			url: '/api/sources/' + store.state.source.name + '/analysis_settings'
		}),
		editAnalysisSettings: data => ({
			method: 'put',
			url: '/api/sources/' + store.state.source.name + '/analysis_settings/' + data.analysisSettingsId
		}),
		getAnalysisSettingsTrees: data => ({
			method: 'get',
			url: '/api/sources/' + store.state.source.name + '/analysis_settings_trees'
		}),
		getLink: data => ({
			method: 'get',
			url: '/api/link/' + data.linkId
		}),
		saveLink: data => ({
			method: 'post',
			url: '/api/sources/' + store.state.source.name + '/views/' + store.state.view.name + '/link_type/' + data.linkType + '/links'
		}),
		getDocumentationUrl: data => ({
			method: 'post',
			url: '/api/documentation'
		}),
		getPageHtml: data => ({
			method: 'get',
			url: '/api/page_html/' + store.state.source.name
		}),
		getStartupData: data => ({
			method: 'get',
			url: '/api/startup_data'
		}),
		getSurveyJsMapping: data => ({
			method: 'get',
			url: '/api/sources/' + store.state.source.name + '/views/' + store.state.view.name + '/survey_js_mapping/' + data.collectionName
		}),
    getAudioUrl: data => ({
			method: 'get',
			url: '/api/sources/' + store.state.source.name + '/audio/' + data.fileName
		}),
		insertSourceViewResult: data => ({
			method: 'post',
			url: '/api/sources/' + store.state.source.name + '/views/' + store.state.view.name + '/result'
		}),
		updateSourceViewResult: data => ({
			method: 'patch',
			url: '/api/sources/' + store.state.source.name + '/views/' + store.state.view.name + '/result/' + data.id
		}),
		deleteSourceViewResult: data => ({
			method: 'delete',
			url: '/api/sources/' + store.state.source.name + '/views/' + store.state.view.name + '/result/' + data.id
		}),
		updateSourceResultDescription: data => ({
			method: 'post',
			url: '/api/sources/' + store.state.source.name + '/views/' + store.state.view.name + '/resultDescription/' + data.id
		}),
		runtimeError: data => ({
			method: 'post',
			url: '/api/error/runtime'
		}),
		pushLog: data => ({
			method: 'post',
			url: '/api/sources/' + store.state.source.name + '/views/' + store.state.view.name + '/log/' + data.type
		}),
		pushHistory: data => ({
			method: 'post',
			url: '/api/sources/' + store.state.source.name + '/views/' + store.state.view.name + '/history'
		}),
		getHistory: data => ({
			method: 'get',
			url: '/api/sources/' + store.state.source.name + '/views/' + store.state.view.name + '/history/' + data.direction
		}),
		sendEmails: data => ({
			method: 'post',
			url: '/api/sources/' + store.state.source.name + '/views/' + store.state.view.name + '/email'
		}),
		twilioToken: data => ({
			method: 'get',
			url: '/api/twilio/token'
		}),
		twilioVoice: data => ({
			method: 'post',
			url: '/api/sources/' + store.state.source.name + '/views/' + store.state.view.name + '/twilio/voice'
		}),
		twilioRecord: data => ({
			method: 'post',
			//url: '/api/twilio/record?to=' + data.to
			url: '/api/sources/' + store.state.source.name + '/views/' + store.state.view.name + '/twilio/record'
		}),
		twilioRecordStop: data => ({
			method: 'post',
			url: '/api/twilio/record_stop'
		}),
		twilioGetRecordings: data => ({
			method: 'post',
			url: '/api/twilio/get_recordings'
		}),

		// **** START surveys
		getSurveys: data => ({
			method: 'get',
			url: '/api/sources/' + store.state.source.name + '/surveys'
    }),
		getSurvey: data => ({
			method: 'get',
			url: '/api/sources/' + store.state.source.name + '/surveys/' + data.id
    }),
		deleteSurvey: data => ({
			method: 'delete',
			url: '/api/sources/' + store.state.source.name + '/surveys/' + data.id
    }),
		insertSurvey: data => ({
			method: 'post',
			url: '/api/sources/' + store.state.source.name + '/surveys'
    }),
		updateSurvey: data => ({
			method: 'patch',
			url: '/api/sources/' + store.state.source.name + '/surveys/' + data.id
    }),
		// **** END surveys

		// **** START variables
		getVariables: data => ({
			method: 'get',
			url: '/api/sources/' + store.state.source.name + '/variables'
    }),
		getVariable: data => ({
			method: 'get',
			url: '/api/sources/' + store.state.source.name + '/variables/' + data.id
    }),
		deleteVariable: data => ({
			method: 'delete',
			url: '/api/sources/' + store.state.source.name + '/variables/' + data.id
    }),
		insertVariable: data => ({
			method: 'post',
			url: '/api/sources/' + store.state.source.name + '/variables'
    }),
		updateVariable: data => ({
			method: 'patch',
			url: '/api/sources/' + store.state.source.name + '/variables/' + data.id
    }),
		updateVariables: data => ({
			method: 'patch',
			url: '/api/sources/' + store.state.source.name + '/variables'
    }),
		// **** END variables

		// **** START questionVariables
		getQuestionVariables: data => ({
			method: 'get',
			url: '/api/sources/' + store.state.source.name + '/question_variables'
    }),
		bulkQuestionVariables: data => ({
			method: 'post',
			url: '/api/sources/' + store.state.source.name + '/question_variables'
    }),
		// **** END questionVariables

		checkTimestamp: data => ({
			method: 'get',
			url: '/api/sources/' + data.sourceName + '/views/' + data.viewName + '/surveys/' + data.surveyId + '/result/' + data.resultId + '/check_timestamp'
    }),

		updateTimestamp: data => ({
			method: 'get',
			url: '/api/sources/' + data.sourceName + '/views/' + data.viewName + '/surveys/' + data.surveyId + '/result/' + data.resultId + '/update_timestamp'
    }),
	}
	return infos[name](data)
}

const api = {
	lastCallTime: null,

	async call(name, options, success, fail) {
		const { url, method } = callInfo(name, options)
		const auth = getInstance()
		const token = await auth.getTokenSilently()

		let requestObj = {
			baseURL: window.location.origin,
			url: url,
			headers: {
        'Authorization': `Bearer ${token}`
			},
			maxContentLength: null,
			method: method,
			params: (options && options.params) ? options.params : {}
		}

		if (!requestObj.params.roleName && store.state.roleName) {
			requestObj.params.roleName = store.state.roleName
		}

		if (options && options.data) {
			requestObj.data = options.data
		}

		api.lastCallTime = Date.now()

		return new Promise(function(resolve) {
      axios(requestObj).then(res => {
				if (success) {
          try {
						success(res.data)
					} catch(e) {
						fail ? fail(e) : error.runtimeError(e)
						return
					}
				}
				
				resolve(res)
			}).catch(async err => {
				let errData = err.response && err.response.data
				let errMessage
				let errorId = errData && errData.errorId
				let type = errData && errData.type
				let errOptions = (errData && errData.options) || {}

				if (errData && errData.errorI18n) {
					errMessage = t('errors.' + errData.errorI18n)
				} else if (errData && errData.error) {
					errMessage = errData.error
				} else {
					errMessage = err
				}

        if (err.response && (err.response.data === 'invalid token' || err.response.status === 401)) {
					// unauthorized
					// this happens most likely when the user has been logged out after beeing inactive

					if (err.response.status === 401) {
						localStorage.setItem('authRedirectUrl', window.location)
						await auth.logout()
					} else {
						store.dispatch('setError', { 
							titleTranslation: 'texts.SESSION_EXPIRED',
							msgTranslation: 'texts.YOUR_SESSION_HAS___',
							buttonText: 'actions.login',
							hideClose: true
						})
					}
				} else if (fail) {
					fail(errMessage, errorId, type, errOptions)
				} else {
					error.serverCommunicationError(errMessage, errorId, type, errOptions)
				}	
			})
		})
	}
}

export default api
